<template>
  <div class="NumberRangeInput">
    <el-input
      type="number"
      class="numrule"
      style="width: 140px"
      placeholder="请输入"
      v-model="StartCost"
      @change="handleChange"
    >
    </el-input>
    <span class="NumberRangeInput-text">至</span>
    <el-input
      type="number"
      class="numrule"
      style="width: 140px"
      placeholder="请输入"
      v-model="EndCost"
      @change="handleChange"
    >
    </el-input>
  </div>
</template>

<script>
export default {
  name: 'NumberRangeInput',
  props: {
    value: {
      type: Array,
      default: () => [null, null], // 初始值为空数组
    },
  },
  data() {
    return {
      StartCost: this.value[0],
      EndCost: this.value[1],
    }
  },
  watch: {
    value(newValue) {
      this.StartCost = newValue[0]
      this.EndCost = newValue[1]
    },
  },
  methods: {
    handleChange() {
      // 触发输入框值变化事件，传递最新的值数组
      this.$emit('input', [this.StartCost, this.EndCost])
    },
  },
}
</script>

<style scoped lang="scss">
/deep/.numrule input::-webkit-outer-spin-button,
/deep/.numrule input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/.numrule input[type='number'] {
  -moz-appearance: textfield;
  border: 0px;
}
.NumberRangeInput {
  display: inline-block;
  border: 1px solid #dcdfe6;
  margin-right: 8px;
  border-radius: 5px;
  .NumberRangeInput-text {
    font-size: 14px;
    margin: 0 10px;
    color: #303133;
  }
}
</style>
